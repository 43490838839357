<template>
  <div>
    <br />
    <p v-if="serverName">Имя текущего медисервера: {{ serverName.name }}</p>
    <p v-if="serverName">Текущее время сервера: {{ isset(() => info.serverTime) ? info.serverTime : -1 }}</p>
    <br />
    <div>
      <button v-on:click="sendPayments()">Отправить платежи</button>
    </div>
    <br>
    <p>Количество платежей: {{ countPayments}}</p>
    <div v-if="flightDetail">
      Текущий рейс:
      {{
        `${flightDetail.number}: ${new Date(flightDetail.date)} ${
          flightDetail.origin
        } - ${flightDetail.destination}`
      }}
      <div>
        <br />
        <button v-on:click="resetCurrentFlight()">Сбросить рейс</button>
      </div>
    </div>
    <br />
    <p>Info:</p>
    <table border="1" width="100%" cellpadding="5">
      <tr>
        <th>Micro °C</th>
        <th>CPU °C</th>
        <th>CPU %</th>
        <th>Used mem %</th>
        <th>Uptime</th>
        <th>TotalClients</th>
      </tr>
      <td>{{ isset(() => info.mikrotikState.temperature) ? (info.mikrotikState.temperature/10).toFixed(1) : -1 }}</td>
      <td>{{ isset(() => info.cpuTemperature) ? getFormatTemp(info.cpuTemperature) : -1 }}</td>
      <td>{{ isset(() => info.cpu) ? info.cpu : -1 }}</td>
      <td>{{ isset(() => info.usedMemPercentage) ? info.usedMemPercentage : -1 }}</td>
      <td>{{ isset(() => info.mikrotikState.uptime) ? info.mikrotikState.uptime : -1 }}</td>
      <td>{{ isset(() => info.mikrotikState.totalClients) ? info.mikrotikState.totalClients : -1 }}</td>
    </table>

    <br />
    <div v-if="isset(() => info.dataADSB)">
      <p>ADSB:</p>
      <table border="1" width="100%" cellpadding="5">
        <tr>
          <th>id</th>
          <th>name</th>
          <th>latitude</th>
          <th>longitude</th>
          <th>altitude</th>
          <th>speed</th>
          <th>seen</th>
          <th>lastSeen</th>
          <th>ground</th>
          <th>ICAO</th>
          <th>firstSeen</th>
        </tr>
        <tr v-for="(data) in info.dataADSB" :key="data.id">
          <td>{{ isset(() => data.id) ? data.id : ''}}</td>
          <td>{{ isset(() => data.name) ? data.name : ''}}</td>
          <td>{{ isset(() => data.latitude) ? data.latitude : ''}}</td>
          <td>{{ isset(() => data.longitude) ? data.longitude : ''}}</td>
          <td>{{ isset(() => data.altitude) ? data.altitude : ''}}</td>
          <td>{{ isset(() => data.speed) ? data.speed : ''}}</td>
          <td>{{ isset(() => data.seen) ? data.seen : ''}}</td>
          <td>{{ isset(() => data.lastSeen) ? data.lastSeen : ''}}</td>
          <td>{{ isset(() => data.ground) ? data.ground : ''}}</td>
          <td>{{ isset(() => data.hasCorrectICAO) ? data.hasCorrectICAO : ''}}</td>
          <td>{{ isset(() => data.firstSeen) ? data.firstSeen : ''}}</td>

        </tr>
      </table>
    </div>

    <br />

    <div v-if="isset(() => info.mikrotikState.bands)">
      <p>Сервера рядом (Данные с микротика):</p>
      <div v-for="(ser) in info.mikrotikState.bands" :key="ser.bandId">
        <div v-if="+ser.freq > 0">
          <br>
          <p>Freq {{ + ser.freq}}:</p>
          <table width="100%" border="1" cellspacing="0" cellpadding="4" v-if="isset(() => info.mikrotikState.bands)">
            <tr>
              <th>macId</th>
              <th>uptime</th>
              <th>rxKBytes</th>
              <th>rxPackets</th>
              <th>rxRateKbps</th>
              <th>signalToNoise</th>
              <th>strength</th>
              <th>txKBytes</th>
              <th>txPackets</th>
              <th>txRateKbps</th>
            </tr>
            <tr v-for="(client) in ser.clients" :key="client.macId">
              <td>{{ convertToHexString(client.macId) }}</td>
              <td>{{ client.uptime }}</td>
              <td>{{ client.rxKBytes }}</td>
              <td>{{ client.rxPackets }}</td>
              <td>{{ client.rxRateKbps }}</td>
              <td>{{ client.signalToNoise }}</td>
              <td>{{ client.strength }}</td>
              <td>{{ client.txKBytes }}</td>
              <td>{{ client.txPackets }}</td>
              <td>{{ client.txRateKbps }}</td>
            </tr>
          </table>
        </div>
      </div>
      <br/>
    </div>
    <div v-if="isset(() => info.mikrotikState.bands)">
      <p>Wi-Fi клиенты:</p>
      <table border="1" width="100%" cellpadding="5">
        <tr>
          <th>Freq</th>
          <th>ClientCount</th>
        </tr>
        <tr v-for="(ser, index) in info.mikrotikState.bands" :key="ser.bandId">
          <td v-if="+ser.freq > 0">{{ ser.freq }}</td>
          <td v-if="+ser.freq > 0">{{ isset(() => info.mikrotikState.bands[index === 0 ? 2 : 3]) ? info.mikrotikState.bands[index === 0 ? 2 : 3].clientCount : -1}}</td>
        </tr>
      </table>

      <br />
    </div>

    <p>Orders:</p>
    <table border="1" width="100%" cellpadding="5">
      <tr>
        <th>#</th>
        <th>CreatedAt</th>
        <th>UpdatedAt</th>
        <th>State</th>
        <th>Seat</th>
        <th>Pin</th>
        <th>Name</th>
        <th>Category</th>
        <th>FlightNumber</th>
      </tr>
      <tr v-for="(order, index) in orders" :key="order.guid">
        <td>{{ index }}</td>
        <td>{{ order.createdAt }}</td>
        <td>{{ order.updatedAt }}</td>
        <td>{{ order.state }}</td>
        <td>{{ order.seat }}</td>
        <td>
          {{
            order && order.upgradeSeat && order.upgradeSeat.pin
              ? order.upgradeSeat.pin
              : ""
          }}
          {{
            order && order.shop && order.shop.pin
              ? order.shop.pin
              : ""
          }}
        </td>
        <td>{{ order && order.items ? order.items[0].name : "" }}</td>
        <td>{{ order.orderCategory }}</td>
        <td>{{ order.flightNumber }}</td>
      </tr>
    </table>

    <br />
    <p>Данные о обнаруженных серверах</p>
    <table border="1" width="100%" cellpadding="5">
      <tr>
        <th>Server name</th>
        <th>Server ip</th>
        <th>Server last discovery</th>
      </tr>
      <tr v-for="server in getDiscoveryInfos" :key="server.name">
        <td>{{ server.name }}</td>
        <td>{{ server.ip }}</td>
        <td>{{ server.time }}</td>
      </tr>
    </table>
    <br />
    <div v-if="posInfo">
      <br />
      <p>Данные POS TERMINAL</p>
      <table border="1" width="100%" cellpadding="5">
        <tr>
          <th>Файл</th>
          <th>Есть на сервере</th>
          <th>JSON</th>
        </tr>
        <tr>
          <td>Flight</td>
          <td>{{ posInfo.flightSet.exists ? 'да' : 'нет' }}</td>
          <td>
            <div v-if="!posInfo.flightSet.exists">-</div>
            <router-link v-if="posInfo.flightSet.exists" :to="{name: 'DetailPosFlight'}" target="_blank" style="color: #1B3E6F">Открыть JSON</router-link>
          </td>
        </tr>
        <tr>
          <td>Inventory</td>
          <td>{{ posInfo.inventorySet.exists ? 'да' : 'нет' }}</td>
          <td>
            <div v-if="!posInfo.inventorySet.exists">-</div>
            <router-link v-if="posInfo.inventorySet.exists" :to="{name: 'DetailPosInventory'}" target="_blank" style="color: #1B3E6F">Открыть JSON</router-link>
          </td>
        </tr>
      </table>
    </div>
    <br />
    <p>Данные о полученных файлах</p>
    <table border="1" width="100%" cellpadding="5">
      <tr>
        <th>Server name</th>
        <th>FlightPlan (flight schedule)</th>
        <th>FlightData (crew, passengers)</th>
        <th>Tariffs</th>
        <th>Images</th>
        <th>Menu</th>
      </tr>
      <tr v-for="box in boxNetworkState" :key="box.serverName">
        <td>{{ box.serverName }}</td>
        <td>{{ box.receivedData.isFlightPlan }}</td>
        <td>{{ box.receivedData.isFlightData }}</td>
        <td>{{ isset(() => box.receivedData.isTariffs) && box.receivedData.isTariffs!== null ? true : false }}</td>
        <td>{{ box.receivedData.isImages }}</td>
        <td>
          ru={{ isset(() => supply.menuFood.ru) }}, en={{
            isset(() => supply.menuFood.en)
          }}
        </td>
      </tr>
    </table>

    <div v-if="isset(() => this.supply.tariff)">
      <br />
      <p>Информация о текущем тарифе</p>
      <table border="1" width="100%" cellpadding="5">
        <tr>
          <th>Title</th>
          <th>TariffType</th>
          <th>JSON</th>
        </tr>
        <tr>
          <td>{{ isset(() => this.supply.tariff.tariffInfo.title) ? this.supply.tariff.tariffInfo.title : '' }}</td>
          <td>{{ isset(() => this.supply.tariff.tariffInfo.tariffType) ? this.supply.tariff.tariffInfo.tariffType : '' }}</td>
          <td><router-link :to="{name: 'DetailTariff'}" target="_blank" style="color: #1B3E6F">Открыть JSON</router-link></td>
        </tr>
      </table>
    </div>

    <br />
    <div v-if="externalControl && externalControl.externalControl === false">
      <select v-model="selected" v-if="!currentFlight">
        <option disabled value="">Выберите один из вариантов</option>
        <option
          v-for="flightPlan in flightLocalPlans"
          :key="flightPlan.id"
          :value="flightPlan.number"
          >{{
            `${flightPlan.number}: ${new Date(flightPlan.date)} ${
              flightPlan.origin
            } - ${flightPlan.destination}
        :${
          isset(() => flightPlan.legs[0].aircraftType)
            ? flightPlan.legs[0].aircraftType
            : null
        } (${
              isset(() => flightPlan.legs[0].aircraftNumber)
                ? flightPlan.legs[0].aircraftNumber
                : ""
            })`
          }}</option
        >
      </select>
      <div v-if="flightLocalPlans && !currentFlight">
        <div id="selectTargets" v-cloak>
          <select v-model="selectedItems" multiple>
            <option
              v-for="(item, key) in getNameServers"
              :value="item"
              :key="key"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <button v-on:click="setFlightPlans()">Установить рейс</button>
      </div>
      <br />
      <div v-if="!currentFlight">
        <button v-on:click="getFlightPlans()">Запросить ближайшие рейсы</button>
        <br />
      </div>
    </div>
    <div v-if="currentFlight">
      <button v-on:click="changeStatusServiceOnBoard()">
        {{ isAvailabilityService ? "Выключить услуги" : "Включить услуги" }}
      </button>
      <br />
      <br />
      Команда:
      <ul id="example-2" v-if="currentFlight.crewEmployers">
        <li
          v-for="crewEmployer in currentFlight.crewEmployers"
          :key="crewEmployer.employeeId"
        >
          {{
            `Id: ${crewEmployer.employeeId} firstNameEN: ${crewEmployer.firstNameEN} firstNameRU: ${crewEmployer.firstNameRU} Position: ${crewEmployer.position} Languages: ${crewEmployer.languages}`
          }}
        </li>
      </ul>
      <div>
        Количество пассажиров:
        {{
          flightDetail && flightDetail.passengersCount
            ? flightDetail.passengersCount
            : currentFlight.passengers.length
        }}
        <div>
          <table
            border="1"
            width="100%"
            cellpadding="5"
            v-if="currentFlight.passengers"
          >
            <tr>
              <th>firstName</th>
              <th>lastName</th>
              <th>nameHash</th>
              <th>seat</th>
              <th>age</th>
              <th>cardCode</th>
              <th>cardType</th>
              <th>cardNumber</th>
              <th>SSR</th>
              <th>Auth Seat</th>
            </tr>
            <tr
              v-for="(passenger, index) in currentFlight.passengers"
              :key="index"
            >
              <td>{{ passenger.firstName }}</td>
              <td>{{ passenger.lastName }}</td>
              <td>{{ passenger.nameHash }}</td>
              <td>{{ passenger.seat }}</td>
              <td>{{ passenger.age }}</td>
              <td>{{ passenger.cardCode }}</td>
              <td>{{ passenger.cardType }}</td>
              <td>{{ passenger.cardNumber }}</td>
              <td>{{ passenger.SSR }}</td>
              <td>{{ !!passenger.isSeatAuth }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "Test",
  data: () => {
    return {
      getDiscoveryInfos: null,
      boxNetworkState: [],
      currentFlight: null,
      flightPlans: null,
      flightLocalPlans: null,
      flightDetail: null,
      selected: null,
      serverName: null,
      externalControl: null,
      orders: null,
      isAvailabilityService: false,
      selectedItems: [],
      supply: null,
      countPayments: null,
      info: null,
      posInfo: null
    };
  },
  created() {
    this.axios.get("/api/admin/getDiscoveryInfo").then(resp => {
      this.getDiscoveryInfos = resp.data;
    });
    this.axios.get("/api/admin/boxNetworkState").then(resp => {
      this.boxNetworkState = resp.data;
      this.selectedItems = this.getNameServers;
    });
    this.axios.get("/api/board/statusServiceOnBoard").then(resp => {
      this.isAvailabilityService = resp.data.isAvailabilityService;
    });
    this.axios.get("/api/board/serverName").then(resp => {
      this.serverName = resp.data;
      this.externalControl = resp.data;
    });
    this.axios.get("/api/board/orders").then(resp => {
      this.orders = resp.data.sort(function(a, b) {
        let dateA = new Date(a.createdAt),
          dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    });
    this.axios.get("/api/admin/supply").then(resp => {
      this.supply = resp.data;
    });
    this.axios.get("/api/flightLocalPlans").then(resp => {
      this.flightLocalPlans = resp.data;
    });
    this.axios.get("/api/pos/info").then(resp => {
      this.posInfo = resp.data;
    });
    this.getApiBranch();
    this.getCurrentFlight();
    this.getFlightDetail();
    this.getCountPayments();
    this.intervalFetchData();
  },
  computed: {
    getNameServers() {
      return this.boxNetworkState.map(networkState => networkState.serverName);
    },
    getTemperature() {
      if(this.serverName && this.serverName.name) {
          const find = this.boxNetworkState.find(server => server.serverName === this.serverName.name)
          return find
      }
      return false
    },
  },
  methods: {
    getFlightPlans() {
      this.axios.get("/api/board/getPlans").then(async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.$router.go(this.$router.currentRoute);
      });
    },
    getApiBranch() {
        this.axios.get("/api/control/getBranchMark").then(resp => {
            this.info = resp.data;
        });
    },
    decimalToHexString(number) {
        {
            if (number < 0)
            {
                number = 0xFFFFFFFF + number + 1;
            }

            return number.toString(16).toUpperCase();
        }
    },
    convertToHexString(ip) {
        return ip.split('.').map(elem => this.decimalToHexString(+elem)).join(':')
    },
     intervalFetchData: function () {
          setInterval(() => {
              this.getApiBranch();
          }, 40000);
     },
    sendPayments() {
        this.axios.get("/api/admin/sendPayments").then(resp => {
            console.log(resp.data)
            this.$router.go(0);
        });
    },
    getCountPayments() {
        this.axios.get("/api/admin/getCountPayments").then(resp => {
            this.countPayments = resp.data
        });
    },
    changeStatusServiceOnBoard() {
      this.axios
        .put("/api/board/statusServiceOnBoard", {
          isAvailabilityService: !this.isAvailabilityService
        })
        .then(resp => {
          console.log(resp.data);
          this.isAvailabilityService = !this.isAvailabilityService;
        });
    },
    getCurrentFlight() {
      this.axios.get("/api/board/currentFlight").then(resp => {
        this.currentFlight = resp.data;
      });
    },
    getFlightDetail() {
      this.axios.get("/api/board/currentFlightDetail").then(resp => {
        this.flightDetail = resp.data;
      });
    },
    formatDate(date) {
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      let yy = date.getFullYear();
      if (yy < 10) yy = "0" + yy;
      return yy + "-" + mm + "-" + dd;
    },
    getFormatTemp(temp) {
          try {
              return temp.replace(/\\n/g, ' ')
          } catch (e) {
              console.log(e.error)
              return -1
          }
    },
    setFlightPlans() {
      let currentFlight = this.flightLocalPlans.find(
        plan => plan.number === this.selected
      );
      currentFlight.date = this.formatDate(
        new Date(currentFlight.date)
      );
      this.axios
        .post("/api/board/currentFlight", {
          targets: this.selectedItems,
          currentTimeUtc: new Date().valueOf(),
          flightPlan: currentFlight
        })
        .then(async () => {
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.go(this.$router.currentRoute);

          // this.getCurrentFlight()
          // this.getFlightDetail()
        });
    },
    isset(accessor) {
      try {
        return typeof accessor() !== "undefined";
      } catch (e) {
        return false;
      }
    },
    resetCurrentFlight() {
      this.axios
        .post("/api/board/currentFlight", {
          targets: this.getNameServers,
          flightPlan: {}
        })
        .then(() => {
          this.$router.go(0);
        });
    }
  },
  beforeDestroy () {
     clearInterval(this.intervalFetchData)
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
